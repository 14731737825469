<template>
  <div class="call">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-header form-title">{{titlePage}}</div>
            <div class="card-body">
              <div class="container form-container">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Cliente</label>
                      <span class="required-field">*</span>
                      <model-list-select
                        :list="createParams.clients"
                        option-value="id"
                        option-text="name"
                        v-model="client_call.client_id"
                        placeholder="Pesquise o cliente"
                        class="form-control-search"
                      ></model-list-select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label>Data</label>
                      <span class="required-field">*</span>
                      <input
                        type="text"
                        class="form-control"
                        v-mask="'##/##/####'"
                        v-on:change="checkDate()"
                        v-model="client_call.call_date"
                      />
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="form-group">
                      <label v-if="formType == 'create'">
                        Gravação
                      </label>
                      <label v-if="formType == 'edit'">Nova Gravação</label>
                      <b-form-file
                        ref="fileinput"
                        placeholder="Escolha um arquivo"
                        drop-placeholder="Arraste o arquivo aqui..."
                        browse-text="Procurar"
                        v-on:change="uploadRecordFile"
                      ></b-form-file>
                    </div>
                  </div>
                  <div class="col-12" v-if="formType == 'edit'">
                    <a :href="client_call.file_url" target="_blank" rel="noopener noreferrer">
                      <button class="btn btn-secondary float-right">Abrir atual</button>
                    </a>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Descrição do atendimento</label>
                      <textarea
                        cols="30"
                        rows="6"
                        v-model="client_call.description"
                        class="form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="dataInfo">Arquivos</div>
                  </div>
                  <div class="col-12 d-flex">
                    <b-form-file
                      ref="newfileinput"
                      placeholder="Escolha um arquivo"
                      drop-placeholder="Arraste o arquivo aqui..."
                      browse-text="Procurar"
                      v-on:change="uploadFile"
                    ></b-form-file>
                    <button
                      class="btn btn-success btn-add-file"
                      v-on:click="addFile()"
                      :disabled="!canAddFile()"
                      v-bind:key="refreshCondition"
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="col-12 col-table">
                    <b-table :fields="fieldsFiles" :items="client_call.files" sticky-header>
                      <template v-slot:cell(id)="data">
                        <div class="d-flex actions-btn">
                          <button class="btn btn-danger" v-on:click="removeFile(data)">
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </button>
                          <a
                            :href="data.item.file_url"
                            v-if="data.item.id"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button class="btn btn-primary">
                              <i class="fa fa-external-link" aria-hidden="true"></i>
                            </button>
                          </a>
                        </div>
                      </template>
                    </b-table>
                    <div class="no-data" v-if="!client_call.files || client_call.files.length == 0">
                      <span>Sem arquivos</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      class="btn btn-primary float-right btn-submit"
                      v-on:click="save()"
                    >Salvar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { bus } from "@/main";
import helper from "@/imports/Helpers";
import validator from "@/imports/validator/validate";
import client_call_validate from "@/imports/validator/models/client_call";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "call",
  components: {
    ModelListSelect
  },
  props: {
    id: Number
  },
  data() {
    return {
      client_call: {
        files: []
      },
      createParams: {
        clients: []
      },
      formType: "create",
      titlePage: "Novo Atendimento",
      newFile: {},
      refreshCondition: false,
      fieldsFiles: [
        { key: "name", label: "Nome", class: "name-cell" },
        { key: "id", label: "Ações", class: "remove-cell" }
      ],
      navigate_items: [
        {
          text: "Atendimentos",
          route: "dashboard.calls"
        },
        {
          text: "Criar",
          active: true
        }
      ]
    };
  },
  mounted() {
    this.getCreateParams();
    if (this.id) {
      this.formType = "edit";
      this.navigate_items[1].text = "Editar";
      this.getDataObject(this.id);
    } else {
      if (this.$route.query.id) {
        this.formType = "edit";
        this.navigate_items[1].text = "Editar";
        this.id = this.$route.query.id;
        this.getDataObject(this.id);
      }
      this.client_call.call_date = helper.currentDateBr();
    }
  },
  methods: {
    getDataObject: function() {
      bus.$emit("changeLoading", true);
      helper.getDataObject("client_calls", this.id).then(response => {
        this.client_call = response.data;
        this.client_call.call_date = this.formatDate(
          this.client_call.call_date
        );
        this.client_call.files = this.client_call.client_call_files;
        if (this.client_call.client != null) {
          this.titlePage = `${this.client_call.client.name} - ${this.client_call.call_date}`;
        } else {
          this.titlePage = "Editar Atendimento";
        }

        bus.$emit("changeLoading", false);
      });
    },
    getCreateParams: function() {
      bus.$emit("changeLoading", true);
      helper.getCreateParams("client_calls").then(data => {
        this.createParams = data.data;
        bus.$emit("changeLoading", false);
      });
    },
    save: function() {
      let errors = validator.validate(
        this.client_call,
        client_call_validate.model
      );
      if (errors.length == 0) {
        this.client_call.operator_id = helper.getLoggedId();
        let data = JSON.parse(JSON.stringify(this.client_call));
        data.call_date = helper.formatBRDateToUs(this.client_call.call_date);
        data.record_file = this.client_call.record_file;
        data.files = this.client_call.files;
        data.client_call_files = JSON.stringify(
          this.client_call.client_call_files
        );
        helper
          .saveFormDataObject(
            "client_calls",
            data,
            this.formType,
            "Atendimento",
            "o"
          )
          .then(() => {
            this.$router.push({ name: "dashboard.calls" });
          });
      } else {
        helper.showErrorsForm(errors);
      }
    },
    addFile: function() {
      this.client_call.files.push(this.newFile.file);
      this.newFile = {};
      this.$refs.newfileinput.reset();
    },
    canAddFile: function() {
      return this.newFile.file != null;
    },
    removeFile: function(file) {
      if (file.item.id) {
        let index = this.client_call.files.findIndex(el => {
          return el.id == file.item.id;
        });
        this.client_call.files.splice(index, 1);
      } else {
        this.client_call.files.splice(file.index, 1);
      }
    },
    uploadFile: function(e) {
      const file = e.target.files[0];
      if (e.target.files[0].size / 1000000 > 50) {
        this.$refs.newfileinput.reset();
        Swal.fire({
          title: "O tamanho máximo permitido é 50MB.",
          icon: "warning"
        });
        return;
      } else {
        this.newFile.file = file;
        this.refreshCondition = !this.refreshCondition;
      }
    },
    uploadRecordFile: function(e) {
      const file = e.target.files[0];
      if (!file.type.includes("audio/")) {
        this.$refs.fileinput.reset();
        Swal.fire({
          title: "Este arquivo não é suportado. Ele não será importado.",
          icon: "warning"
        });
        return;
      } else if (e.target.files[0].size / 1000000 > 50) {
        this.$refs.fileinput.reset();
        Swal.fire({
          title: "O tamanho máximo permitido é 50MB.",
          icon: "warning"
        });
        return;
      } else {
        this.client_call.record_file = file;
      }
    },
    checkDate: function() {
      if (!helper.validateDateBR(this.client_call.call_date)) {
        this.client_call.call_date = "";
        Swal.fire({
          title: "Insira uma data válida",
          icon: "warning"
        });
      }
    },
    formatDate: function(date) {
      return helper.formatDate(date);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/general.scss";

.call {
  .name-cell {
    width: 90%;
  }
  .remove-cell {
    width: 10%;
  }
  .col-table {
    padding-top: 28px;
  }
  .btn-add-file {
    margin-left: 8px;
  }
  .actions-btn {
    button {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
  .form-control-search {
    .search {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da !important;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}
</style>