export default {
  model: [
    {
      label: 'Data',
      field: 'call_date',
      type: 'text'
    },
    // {
    //   label: 'Gravação',
    //   field: 'record_file',
    //   type: 'text',
    //   condition_field: 'id',
    //   condition_type_exists: false
    // },
    {
      label: 'Cliente',
      field: 'client_id',
      type: 'text'
    },
  ]
}